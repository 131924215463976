#ReactappBody {
    min-height: calc(100vh - 193px);
    display: grid;
    grid-template-rows: min-content;
}
.dropdown-parent::after {
    content: "▼";
    padding: 12px 8px;
    right: 10px;
    top: 0;
    z-index: 1;
    text-align: center;
    width: 10%;
    height: 100%;
    pointer-events: none;
    margin-left: 10px;
}
.modal {
    width: 80%;
}
