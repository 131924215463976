.datepicker-modal .datepicker-container {
    height: initial !important;
}
.datepicker-modal {
    height: 347px;
}
.datepicker-footer {
    display: none;
}
.timepicker-modal .timepicker-container {
    height: initial !important;
}
.timepicker-modal {
    height: 346px;
}
