.menu-dropdown {
    position: absolute;
    right: 10px;
    display: flex;
    justify-content: flex-end;
}
.menu-dropdown li {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0 10px;
    transition: 1s;
}
.menu-dropdown li:hover {
    background-color: rgba(20, 148, 233, 0.5);
}
.menu-dropdown li a:hover {
    background-color: initial;
}
