.video-list-item {
    width: 150px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    margin-right: 25px;
}
.video-list-item:hover > .video-list-action {
    display: block;
}
.video-list-item img {
    width: 100%;
}
.video-list-item .video-list-action {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}
.video-list-item .video-list-action i {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: #fff;
}
#video-collection-modal video {
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#video-collection-modal {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: unset;
    overflow: hidden;
    width: 0px !important;
    overflow: visible;
}
#video-collection-modal .modal-content {
    overflow: unset;
}
