/**
* #COLORS
**/
:root {
    --black: #131415;
    --white: #fff;
    --orange: #fd7e14;
    --transparent: transparent;

    --dark-grey: #343a40;
    --light-grey: #ccc;
    --light-orange: #ffd4b0;
    --mid-orange: #ffa860;
    --dark-orange: #ab530a;

    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;

    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --grey: #6c757d;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;

    --wide: 1400px;
    --spacer: 180px;
    --gap: 10px;
    --gap2x: 20px;
    --gap4x: 40px;
    --gap6x: 60px;
    --gap10x: 100px;
    --header: 100px;
    --header2: 50px;
}
/*
Navbar
*/
nav ul li.active {
    background-color: var(--orange);
}
/*
Helpers
*/
.cursor-pointer {
    cursor: pointer;
}

.orange {
    background-color: var(--orange) !important;
}
/**
    GLOBAL
*/
a {
    color: var(--white);
}
body {
    background-color: var(--black);
    color: var(--light);
}

/**
    CARD
*/
.card {
    background-color: transparent;
}
/**
    NAVBAR
*/
nav {
    background-color: var(--black);
}
nav ul a:hover {
    background-color: var(--orange);
}
.z-depth-1,
nav,
.card-panel,
.card,
.toast,
.btn,
.btn-large,
.btn-small,
.btn-floating,
.dropdown-content,
.collapsible,
.sidenav {
    box-shadow: 0 2px 2px 0 rgb(255 255 255 / 50%),
        0 3px 1px -2px rgb(255 255 255 / 12%),
        0 1px 5px 0 rgb(255 255 255 / 20%);
}
/**
    SIDENAV
*/

.sidenav {
    background-color: var(--orange);
    color: var(--black);
}
/**
    MODAL
*/

.modal {
    background-color: var(--black);
    border: 1px solid var(--orange);
}
.modal .modal-footer {
    background-color: var(--black);
    border: 1px solid var(--orange);
    color: var(--orange);
}
.modal .modal-footer button {
    color: var(--orange);
}
/**
SCROLLBAR
*/

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--orange);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: var(--orange);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--mid-orange);
}
/**
    FORMULARIO
*/
.select-dropdown,
.dropdown-trigger {
    color: var(--white);
}
.dropdown-content {
    background-color: var(--secondary);
}
.form-checkbox,
.form-radio label > p {
    color: var(--white);
}
.input-field label {
    color: var(--white);
}
form input {
    color: var(--white);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--black) inset !important;
    box-shadow: 0 0 0 30px var(--black) inset !important;
}
input:-webkit-autofill {
    -webkit-text-fill-color: var(--light) !important;
}
.quill {
    color: var(--light);
}
[type="checkbox"]:checked + span:not(.lever):before {
    border-right: 2px solid var(--orange);
    border-bottom: 2px solid var(--orange);
}
[type="radio"]:not(:checked) + span:before,
[type="radio"]:not(:checked) + span:after {
    border: 2px solid var(--white);
}
[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:after {
    background-color: var(--orange);
}
[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
    border: 2px solid var(--orange);
}
input:not([type]):focus:not([readonly]),
input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="url"]:not(.browser-default):focus:not([readonly]),
input[type="time"]:not(.browser-default):focus:not([readonly]),
input[type="date"]:not(.browser-default):focus:not([readonly]),
input[type="datetime"]:not(.browser-default):focus:not([readonly]),
input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
input[type="tel"]:not(.browser-default):focus:not([readonly]),
input[type="number"]:not(.browser-default):focus:not([readonly]),
input[type="search"]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 1px solid var(--orange);
    box-shadow: 0 1px 0 0 var(--orange);
}
input:not([type]):focus:not([readonly]) + label,
input[type="text"]:not(.browser-default):focus:not([readonly]) + label,
input[type="password"]:not(.browser-default):focus:not([readonly]) + label,
input[type="email"]:not(.browser-default):focus:not([readonly]) + label,
input[type="url"]:not(.browser-default):focus:not([readonly]) + label,
input[type="time"]:not(.browser-default):focus:not([readonly]) + label,
input[type="date"]:not(.browser-default):focus:not([readonly]) + label,
input[type="datetime"]:not(.browser-default):focus:not([readonly]) + label,
input[type="datetime-local"]:not(.browser-default):focus:not([readonly])
    + label,
input[type="tel"]:not(.browser-default):focus:not([readonly]) + label,
input[type="number"]:not(.browser-default):focus:not([readonly]) + label,
input[type="search"]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
    color: var(--orange);
}

textarea {
    color: var(--white);
}

.timepicker-canvas line {
    stroke: var(--orange);
}

.timepicker-canvas-bearing,
.timepicker-canvas-bg {
    fill: var(--orange);
}

.timepicker-digital-display {
    background-color: var(--orange);
}

.timepicker-close {
    color: var(--orange);
}

.datepicker-date-display {
    background-color: var(--orange);
}

.datepicker-table td.is-today {
    color: var(--orange);
}

.datepicker-cancel,
.datepicker-clear,
.datepicker-today,
.datepicker-done {
    color: var(--orange);
}

.datepicker-table td.is-selected {
    background-color: var(--orange);
}

.input-field .prefix .active {
    color: var(--orange);
}

/**
    BOTONES
*/

.bt {
    display: inline-block;
    padding: var(--gap2x);
    padding-left: var(--gap4x);
    padding-right: var(--gap4x);
    border: 1px solid var(--orange);
    background-color: var(--orange);
    font-size: 1.1rem;
    line-height: 1em;
    border-radius: var(--gap4x);
    font-family: var(--bold);
    transition: all 0.2s ease-in-out;
    height: 3.6rem;
}
.btn:hover,
.btn-large:hover,
.btn-small:hover {
    background-color: var(--black);
    color: var(--light);
}

/**
    MODAL SWAL alert2
*/
.swal2-confirm {
    background-color: var(--orange) !important;
}
.swal2-deny {
    background-color: var(--black) !important;
}

/**
    TABLE
*/

table tr:not(:first-child) {
    border-top: 1px solid var(--orange);
}
.search-react-table-input {
    color: var(--white);
}

/**
    COLLECTION
    */
.collection-item span.badge {
    margin-top: calc(0.75rem - 11px);
}
.collection .collection-item {
    background-color: transparent;
}
.collection.with-header .collection-header {
    background-color: transparent;
}
.collection .collection-item.active {
    background-color: var(--light-orange);
    color: var(--black);
}
